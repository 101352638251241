(function ($, window, document, undefined) {

    /**
     * Monkey-patch window.XMLSerializer
     */
    if (!window.XMLSerializer) {
        window.XMLSerializer = function () { };

        window.XMLSerializer.prototype.serializeToString = function (XMLObject) {
            return XMLObject.xml || '';
        };
    }

    window.availableLanguages = ['en', 'es', 'ru', 'tr', 'zh'];
    window.parseGamesXML = function (game_type, game_name, search_game) {
        // Load the games xml file using ajax
        $.ajax({
            type: "GET",
            url: window.currentPrefix + "js/xml/" + window.currentLanguage + "games-list.xml",
            dataType: 'xml',
            complete: function (xml) {

                // Games list
                if (typeof game_type !== 'undefined') {

                    if (game_type === 'jackpots') {
                        game_type = 'slots, table, poker';
                    }

                    // Parse the xml file and get data
                    var xmlDoc = $.parseXML(xml.responseText),
                        $games = $(xmlDoc).find("games").children(game_type),
                        $gamesArray = $games.find('game'),
                        gamesHTML = '';


                    if (typeof search_game !== 'undefined') {

                        $gamesArray = $games.find('game').filter(function (index, game) {
                            if ($(game).children('title')[0].innerHTML.toLowerCase().indexOf(search_game) !== -1) {
                                return true;
                            }
                        });

                    }

                    $gamesArray.each(function () {

                        var $game = $(this),
                            $title = $game.find('title').text(),
                            $href = $game.find('href').text(),
                            $image = $game.find('image').text(),
                            $type = $game.find('type').text();

                        var html = '<div class=\"box game-box\">';
                        html += '<a href=' + $href + '>';
                        html += '<img src=' + window.currentPrefix + $image + ' class="game-image" alt="' + $title + '">';
                        html += '<div class="game-box-ribbon-wrapper"><div class="game-box-ribbon">' + $('.boxes').data('preview-text') + '<img class="game-ribbon-image" src=' + window.currentPrefix + 'img/ribbon-triangle.svg' + '></div>';
                        html += '</div>';
                        html += '</a>';
                        html += '<span class="left">' + $title + '</span>';
                        html += '<span class="right">' + $type + '</span>';
                        html += '</div>';

                        gamesHTML += html;
                    });

                    if (!gamesHTML) {
                        gamesHTML = 'There are no results for your current search.';
                    }

                    $('body.games').not(".individual").find('div.boxes').html(gamesHTML);
                }

                // Individual game info
                if (typeof game_name !== 'undefined') {
                    // Parse the xml file and get data
                    var xmlDoc2 = $.parseXML(xml.responseText),
                        $game_info = $(xmlDoc2).find("games").find('game#' + game_name),
                        active_game_type = $game_info.parent()[0].nodeName;

                    var title = $game_info.find('title').text(),
                        image = $game_info.find('image').text(),
                        $descNode = $game_info.find('desc'),
                        desc = (new XMLSerializer()).serializeToString($descNode.get(0));

                    var html = '<div class="boxes">';
                    html += '<div class="box game-box">';
                    html += '<img src="' + window.currentPrefix + image + '" alt="' + title + '">';
                    html += '</div>';
                    html += '</div>';

                    html += '<div class="game-description">';
                    html += '<h2 class="title">' + title + '</h2>';
                    html += '<div class="desc">' + desc + '</div>';
                    html += '</div>';
                    html += '<div class="clearfix"></div>';

                    $('body.games.individual').find('.content > .row').append($(html));

                    // Popup window setup
                    $('.btn-fullscreen').attr('href', '#' + game_name);

                }

                $(document).trigger('gamesloaded');
            }
        });
    };


    /**
    * Get innerwidth
    * @return {[type]} [description]
    */
    function getInnerWidth() {

        var windowInnerWidth = 0;

        if (typeof window.innerWidth !== 'undefined') {
            windowInnerWidth = window.innerWidth;
        } else {
            windowInnerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }

        return windowInnerWidth;
    }

    /**
    * Get innerHeight
    * @return {[type]} [description]
    */
    function getInnerHeight() {

        var windowInnerHeight = 0;

        if (typeof window.innerHeight !== 'undefined') {
            windowInnerHeight = window.innerHeight;
        } else {
            windowInnerHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        }

        return windowInnerHeight;
    }


    /**
     * Calculate game sizes
     */
    function calcGameSize(size) {

        var maxWidth = $('.game-wrapper').outerWidth(),
            maxFSHeight = $('.game-wrapper').height(),
            frameWidth = $('iframe#game').prop('width'),
            frameHeight = $('iframe#game').prop('height'),
            gameRatio = frameWidth / frameHeight,
            gameRowHeight = $('iframe#game').closest('.row').height();

        if (size === 'fullscreen') {

            frameFSHeight = maxFSHeight - 60;
            frameFSWidth = parseInt(frameFSHeight * gameRatio);

            if (frameFSWidth >= maxWidth) {
                excessFSWidth = frameFSWidth - maxWidth;
                resizedFSHeight = parseInt(frameFSHeight - excessFSWidth / gameRatio);
                $('iframe#game').width(maxWidth).height(resizedFSHeight);
            } else {
                $('iframe#game').width(frameFSWidth).height(frameFSHeight);
            }

            if (getInnerWidth() < getInnerHeight()) {
                if (getInnerWidth() >= 992) {
                    $('.game-wrapper > .row').css({
                        top: '',
                        marginTop: ''
                    });
                } else {
                    $('.game-wrapper > .row').css({
                        top: '50%',
                        marginTop: -Math.abs(gameRowHeight / 2)
                    });
                }
            }

        } else {

            if (frameWidth >= maxWidth) {
                excessWidth = frameWidth - maxWidth;
                resizedHeight = parseInt(frameHeight - excessWidth / gameRatio);
                $('iframe#game').width(maxWidth).height(resizedHeight);
            } else {
                $('iframe#game').width(frameWidth).height(frameHeight);
            }

            $('.game-wrapper > .row').css({
                top: '',
                marginTop: ''
            });

        }

    }


    function slickSliderDots(e) {
        if ($('.slider-dots-container').length > 0) {

            if (getInnerWidth() >= 992) {
                var containerCenter = $('.slider-dots-container').parent('.container').width() / 2;
                var sliderDotsCenter = $('.slider-dots-container').width() / 2;
                $('.slider-dots-container').css('left', Math.abs(containerCenter - sliderDotsCenter));
            } else {
                $('.slider-dots-container').css('left', '');
            }
        }

    }


    /*
    * GAMES PAGINATION HIDE/SHOW BACK/NEXT
    */
    function hideBackNextButton(e) {
        $('.games-pagination').each(function () {
            if ($(this).find('.active').prev().text() === "<<") {
                $(this).find('.back').hide();
                $(this).find('.next').show();
            } else if ($(this).find('.active').next().text() === ">>") {
                $(this).find('.next').hide();
                $(this).find('.back').show();
            } else {
                $(this).find('.back').show();
                $(this).find('.next').show();
            }
        });
    }


    // CREATE GAMES PAGINATION
    function createGamesPagination(e) {
        var pages_count = $('.boxes').children('.pagination').length;
        if (pages_count > 0) {
            var games_pagination = '<li class="back"><a href="#"><<</a></li>';
            for (var i = 0; i < pages_count; i++) {
                if (i === 0) {
                    games_pagination += '<li class="active"><a href="#">' + (i + 1) + '</a></li>';
                } else {
                    games_pagination += '<li><a href="#">' + (i + 1) + '</a></li>';
                }
            }
            games_pagination += '<li class="next"><a href="#">>></a></li>';
            $('.games-pagination').append($(games_pagination));
        }

        if (pages_count === 1) {
            $('.games-pagination').hide();
        } else {
            $('.games-pagination').show();
        }
    }


    // SHOW ACTIVE PAGE DEPENDING ON PAGINATION
    function setActivePageByPagination() {
        var active_page = $('.games-pagination').find('.active').index();
        $('body.games').not(".individual").find('.pagination').hide();
        $('body.games').not(".individual").find('.pagination.page-' + active_page).show();
    }


    // GAMES PAGINATION NEXT BUTTON
    function paginationNextBtnAction(e) {
        $('.next').bind('click', function (e) {
            e.preventDefault();
            var current_index = $(this.parentElement).find('.active').index();
            $('.games-pagination').each(function () {
                var current = $($(this).children('li').get(current_index));
                var next = current.next('li');

                if (!next.hasClass('next')) {
                    current.removeClass('active');
                    next.addClass('active');
                }
                hideBackNextButton();
                setActivePageByPagination();
            });
        });
    }


    // GAMES PAGINATION BACK BUTTON
    function paginationBackBtnAction(e) {
        $('.back').bind('click', function (e) {
            e.preventDefault();
            var current_index = $(this.parentElement).find('.active').index();
            $('.games-pagination').each(function () {
                var current = $($(this).children('li').get(current_index));
                var back = current.prev('li');

                if (!back.hasClass('back')) {
                    current.removeClass('active');
                    back.addClass('active');
                }
                hideBackNextButton();
                setActivePageByPagination();
            });
        });
    }


    // GAMES PAGINATION DIRECT PAGE CLICK
    function paginationClickAction(e) {
        $('.games-pagination li').click(function (e) {
            e.preventDefault();
            if (e.currentTarget.className === 'back' || e.currentTarget.className === 'next') {
                return false;
            }
            var current_index = $(this).index();
            $('.games-pagination').each(function () {
                var current = $($(this).children('li').get(current_index));
                $(this).children('li').removeClass('active');
                $(current).addClass('active');
            });
            hideBackNextButton();
            setActivePageByPagination();
        });
    }


    // WRAP ALL GAMES (BY 9 IN THIS CASE) INTO PAGES
    function wrapAllGames(number) {
        var $boxesArr = $('body.games').not(".individual").find('div.boxes').children('.box');
        var boxesArrLen = $boxesArr.length;
        var boxesPerDiv = typeof number !== 'undefined' ? number : 9;
        var wrap_loop_count = 1;
        for (var i = 0; i < boxesArrLen; i += boxesPerDiv) {
            var $boxesGroup = $boxesArr.filter(':eq(' + i + '), :lt(' + (i + boxesPerDiv) + '):gt(' + i + ')');
            $boxesGroup.wrapAll('<div class="pagination page-' + wrap_loop_count + '" />');
            wrap_loop_count++;
        }
    }


    function fixNavOnScroll() {
        // For About Us page set sidebar height same as content
        var $parent = $(".about-us .sidebar-nav");
        var $child = $parent.children('ul');
        // SCROLL ABOUT US NAV
        var parent_top = $parent.offset().top;
        var parent_bottom = $parent.offset().top + $parent.height() - $child.height();
        var y = $(document).scrollTop();
        if (window.outerWidth >= 768) {
            if ((y - 70) >= parent_top && y < parent_bottom) {
                $child.css({ "position": "fixed", "top": "70px", "bottom": "" });
            } else if ((y - 70) >= parent_top && y >= parent_bottom) {
                $child.css({ "position": "absolute", "top": "", "bottom": "50px" });
            } else {
                $child.css({ "position": "", "top": "", "bottom": "" });
            }
        } else {
            $child.css({ "position": "", "top": "", "bottom": "" });
        }
    }

    function setNavWidthHeight() {
        // For About Us page set sidebar height same as content
        var $parent = $(".about-us .sidebar-nav");
        var $child = $parent.children('ul');
        // SET WIDTH AND HEIGHT
        $parent.outerHeight($('.about-us-content').outerHeight());
        $child.width($parent.width());
    }


    $(document).ready(function () {

        if (window.availableLanguages) {
            var languageSwitcher = '<li class="language-switcher dropdown">',
                currentLang = window.currentLanguage ? window.currentLanguage.substring(0, window.currentLanguage.length - 1) : 'en';

            if (window.availableLanguages.length > 1) {
                languageSwitcher += '<ul class="dropdown-menu">';
                for (var i = 0; i < window.availableLanguages.length; i++) {
                    var activeClass = window.availableLanguages[i] == currentLang ? 'class="active"' : '';
                    if (window.availableLanguages[i] == 'en') {
                        languageSwitcher += '<li ' + activeClass + '><a href="/' + window.location.pathname.slice(4, window.location.pathname.length) + window.location.hash + '">' + window.availableLanguages[i].toUpperCase() + '</a></li>';
                    } else {
                        if (currentLang == 'en') {
                            languageSwitcher += '<li ' + activeClass + '><a href="/' + window.availableLanguages[i] + window.location.pathname + window.location.hash + '">' + window.availableLanguages[i].toUpperCase() + '</a></li>';
                        } else {
                            languageSwitcher += '<li ' + activeClass + '><a href="/' + window.availableLanguages[i] + '/' + window.location.pathname.slice(4, window.location.pathname.length) + window.location.hash + '">' + window.availableLanguages[i].toUpperCase() + '</a></li>';
                        }
                    }
                }
                languageSwitcher += '</ul>';
            }

            languageSwitcher += '</li>';

            languageSwitcher = $(languageSwitcher);

            var dropdownTrigger = $('<a href="#" data-toggle="dropdown" data-hover="dropdown">' + currentLang.toUpperCase() + '</a>');
            languageSwitcher.prepend(dropdownTrigger);

            $('.header-main-nav').append(languageSwitcher);

            if ($.fn.dropdownHover) {
                dropdownTrigger.dropdownHover();
            }
        }

        // Show/Hide main menu on menu button click when on mobile
        $('.navbar-collapse').on('show.bs.collapse', function (event) {
            $(event.target).parent().parent().parent().show();
        });
        $('.navbar-collapse').on('hide.bs.collapse', function (event) {
            $(event.target).parent().parent().parent().slideUp();
        });

        // Define slick.js slider variable
        var $slickSlider = $('.ae-slick-slider');

        // On slider init change the game-link href
        $slickSlider.on('init', function (event, slick) {
            var initLink = $(slick.$slides.get(0)).find('input').val();
            $('.btn-game-link').attr('href', initLink);
            $('.slider-text').html($(slick.$slides.get(0)).find('.game-text').html());
        });

        // On slide change - change the game-link href
        $slickSlider.on('afterChange', function (event, slick, currentSlide, nextSlide) {
            var currentLink = $(slick.$slides.get(currentSlide)).find('input').val();
            $('.btn-game-link').attr('href', currentLink);
            $('.slider-text').html($(slick.$slides.get(currentSlide)).find('.game-text').html());
        });

        // Initiate the slider
        $slickSlider.slick({
            dots: true,
            autoplay: false,
            autoplaySpeed: 5000,
            appendDots: $('.slider-dots-container'),
        });

        // Show/Hide main menu when resizing the browser window
        $(window).resize(function (event) {
            if (getInnerWidth() >= 480) {
                $('.main-menu').show();
            } else {
                $('.main-menu').slideUp();
            }
        });

        // Game page Enter/Exit Fullscreen button
        $('.game-wrapper').find('.btn-fullscreen').click(function () {
            $("body").toggleClass('fullscreen');
            if ($("body").hasClass('fullscreen')) {
                calcGameSize('fullscreen');
                $(this).html('<span class="icon-fullscreen-exit-alt"></span> Exit Fullscreen');
            } else {
                calcGameSize();
                $(this).html('<span class="icon-fullscreen-alt"></span> Fullscreen');
            }
        });

        // Set nav active classes
        if ($('body').hasClass('about-us')) {
            if (window.location.hash) {
                // $( '.sidebar-nav' ).find( 'li' ).removeClass( 'active' );
                $('.sidebar-nav').find('a[href="' + window.location.hash + '"]').parent('li').addClass('active');
            }
            $(".sidebar-nav ul li a").on('click', function (event) {
                $(this).closest('ul').find('li').removeClass('active');
                $(this).parent('li').addClass('active');
            });
        }


        if ($('.search-input').length > 0) {

            $('.search-input').closest('form').on('submit', function (event) {
                event.preventDefault();

                var searchInput = $('.search-input').val().trim();
                if (searchInput) {
                    parseGamesXML(window.currentGamesType, undefined, searchInput);

                    $(document).on('gamesloaded', function (event) {

                        wrapAllGames();
                        $('.games-pagination').html('');
                        createGamesPagination();
                        hideBackNextButton();
                        paginationNextBtnAction();
                        paginationBackBtnAction();
                        setActivePageByPagination();
                        paginationClickAction();
                    });

                } else {
                    parseGamesXML(window.currentGamesType);

                    $(document).on('gamesloaded', function (event) {

                        wrapAllGames();
                        $('.games-pagination').html('');
                        createGamesPagination();
                        hideBackNextButton();
                        paginationNextBtnAction();
                        paginationBackBtnAction();
                        setActivePageByPagination();
                        paginationClickAction();
                    });
                }
            });
        }

    });


    $(window).load(function () {
        trueWidth = $(window).width();
        trueHeight = $(window).height();

        calcGameSize();
        slickSliderDots();

        setTimeout(function () {
            wrapAllGames();
            createGamesPagination();

            hideBackNextButton();
            paginationNextBtnAction();
            paginationBackBtnAction();
            setActivePageByPagination();
            paginationClickAction();

            // Set first page active
            // $( '.games-pagination li' ).first().next( 'li' ).click();
        }, 200);

        if ($('body').hasClass('about-us')) {
            // FIX ABOUT US NAV WIDTH/HEIGHT
            setNavWidthHeight();
            // FIX ABOUT US NAV ON SCROLL
            fixNavOnScroll();
            $(document).scroll(function () {
                fixNavOnScroll();
            });
        }

    });


    $(window).resize(function () {

        if ($("body").hasClass('individual')) {

            if ($("body").hasClass('fullscreen')) {
                calcGameSize('fullscreen');
            } else {
                calcGameSize();
            }
        }

        if ($('body').hasClass('about-us')) {
            // FIX ABOUT US NAV WIDTH/HEIGHT
            setNavWidthHeight();
            // FIX ABOUT US NAV ON SCROLL
            fixNavOnScroll();
        }

        slickSliderDots();

        trueWidth = $(window).width();
        trueHeight = $(window).height();
    });

})(jQuery, window, document);
